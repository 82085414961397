import { localStorageHelper } from "../hooks/localStorageHelper";
import { Roles, ROLES_KEY } from "./Constants";

export const getYearDiffWithMonth = (startDate: Date, endDate: Date) => {
    const ms = endDate.getTime() - startDate.getTime();
  
    const date = new Date(ms);
    return Math.abs(date.getUTCFullYear() - 1970);
}
  
export const parseJwt =  (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const hasRole = (roleName: Roles) : boolean => {
    const roles: [string] = localStorageHelper.load(ROLES_KEY);
    return roles.includes(roleName);
}

/**
 * Returns true if the user has any of the roles passed in the array
 * @param roleNames - list of roles to check
 * @returns 
 */
export const hasAnyRole = (roleNames: Roles[]) : boolean => {
    const roles: [string] = localStorageHelper.load(ROLES_KEY);
    return roleNames.some(role => roles.includes(role));
}