import API from '../../../hooks/api';
import {
    APP_REJECTIONS_TRACKING_SUMMARY_URL,
    APP_TRACKING_LOAN_REJECTIONS_URL,
    APP_TRACKING_REGISTRATION_REJECTIONS_URL
} from '../../../utils/Endpoints';
import { CustomerTrackingInfoModel, ReportFilter, ReportSummaryModel } from './Screenhelper';
const { REACT_APP_BASE_URL } = process.env;

export const getRejectionTrackingSummary = async (callBack: (success: boolean, data: ReportSummaryModel, errorMsg: string | null) => void = () => { }
) => {
    await API.get(REACT_APP_BASE_URL + APP_REJECTIONS_TRACKING_SUMMARY_URL, {
    })
        .then((res) => {
            const data = res.data;
            callBack(true, data, null);
            return res.data;
        }).catch((err) => {
            callBack(false, {}, err);
        });
}

export const getRejectedLoans = async (data: ReportFilter,
    callBack: (success: boolean, data: CustomerTrackingInfoModel[], errorMsg: string | null) => void = () => { }
) => {
    const paramsObj = {
        page: (data.page ?? 1),
        size: 10,
        searchText: data.search
    }

    await API.get(REACT_APP_BASE_URL + APP_TRACKING_LOAN_REJECTIONS_URL, {
        params: paramsObj
    })
        .then((res) => {
            const data = res.data;
            callBack(true, data.items, null);
            return res.data;
        }).catch((err) => {
            callBack(false, [], err);
        });
}

export const getRejectedRegistration = async (data: ReportFilter,
    callBack: (success: boolean, data: CustomerTrackingInfoModel[], errorMsg: string | null) => void = () => { }
) => {
    const paramsObj = {
        page: (data.page ?? 1),
        size: 10,
        searchText: data.search
    }

    await API.get(REACT_APP_BASE_URL + APP_TRACKING_REGISTRATION_REJECTIONS_URL, {
        params: paramsObj
    })
        .then((res) => {
            const data = res.data;
            callBack(true, data.items, null);
            return res.data;
        }).catch((err) => {
            callBack(false, [], err);
        });
}