const { REACT_APP_BASE_URL } = process.env;

//Endpoint for fetching the app settings.
export const APP_SETTINGS_V2_URL = "/operations-order/v2/app/settings";

//Endpoint for updating the app settings.
export const APP_SETTINGS_V1_URL = "/operations-order/app/settings";


//CUSTOMER endpoints.
//Endpoint to fetch the customer list.
export const CUSTOMER_LIST_URL = "/customer/v2/list";

//Endpoint to deactivate the customer
export const CUSTOMER_DEACTIVATE_URL = "/customer/deactivate";

//Endpoint for updating the cusotmer block status.
export const CUSTOMER_UPDATE_BLOCK_STATUS_URL = "/operations/blocked-status";


//Endpoint for fetching the intra anb statements.
export const APP_INTRA_STATEMENTS_V2_URL = "/operations-order/v2/intra/statements";

//Endpoint for fetching the loans status.
export const APP_LOANS_V2_URL = "/operations-order/v2/loans";

//Endpoint for fetching the credit quality data.
export const APP_CREDIT_QUALITY_API_V2_URL = "/operations-order/v2/getQualityData";

//Endpoint for fetching the account balance
export const APP_ACCOUNT_BALANCE_V2_URL = "/operations-order/v2/quara/account/balance";

//Endpoint for fetching the tracking summary
export const APP_REJECTIONS_TRACKING_SUMMARY_URL = "/operations/rejections/tracking-summary";

//Endpoint for fetching the loan rejection list
export const APP_TRACKING_LOAN_REJECTIONS_URL = "/operations/tracking/loan-rejections";

//Endpoint for fetching the registration rejection list
export const APP_TRACKING_REGISTRATION_REJECTIONS_URL = "/operations/tracking/registration-rejections";

//Endpoint for fetching the login statistics
export const APP_DASHBOARD_LOGIN_COUNT_URL = "/operations/app-login-statistics";

//Endpoint for fetching the registration statistics
export const APP_DASHBOARD_REGISTRATION_COUNT_URL = "/operations/app-registration-statistics";

//Endpoint for fetching the loan statistics
export const APP_DASHBOARD_LOANS_COUNT_URL = "/operations-order/v2/app/loan-statistics";

//Endpoint for fetching the loan statistics by category
export const APP_DASHBOARD_LOANS_BY_CATEGORY_COUNT_URL = "/operations-order/v2/app/loan-category-statistics";

//Endpoint for fetching the payment statistics
export const APP_DASHBOARD_PAYMENTS_COUNT_URL = "/operations-order/v2/app/payment-statistics";

//Endpoint for fetching the payouts
export const APP_PAYOUTS_URL = "/operations-order/v2/payouts";

//Endpoint for multiple payout updates
export const APP_MULTIPLE_PAYOUT_UPDATE = "/operations-order/v2/updatePayouts";

/**
 * 
 * @param url - The url to be appended to the base url.
 * @returns - The full url.
 */
export function getFullURL(url: string) {
    return REACT_APP_BASE_URL + url;
}