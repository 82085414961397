import exportFromJSON from 'export-from-json';
import { ALL } from '../../../utils/Constants';
import { getIntraStatements } from './API';

export interface filters {
    transactionType?: string;
    page?: number;
    size?: number;
    search?: string;
    pageSize?:number;
}

export interface IntraStatement {
    refNum?: string;
    narr1?: string;
    narr2?: string;
    narr3?: string;
    postDate?: string;
    postingTime?: string;
    valueDate?: string;
    type?: string;
    counter?: string;
    stmt?: string;
    partTrnType?: string;
    partTrnSrlNum?: string;
    samaNarr?: string;
    network?: string;
    channel?: string;
    closingBalanceAmount?: string;
    closingBalanceCurrency?: string;
    transactionAmount?: string;
    transactionCurrency?: string;
}

export interface IntraStatementList {
    items: IntraStatement[]
}

export const transactionTypes = [ALL, "CREDIT", "DEBIT"]


// we calling this function when we need to get all records fromdatabase
export const callRequest = async (setData: React.Dispatch<React.SetStateAction<IntraStatement[]>>, filters: filters, initFilters?: filters) => {
    setData([]);
    await getIntraStatements(initFilters ?? filters, (_, data) => setData(data || []));

}

// Printing pagination items 
export const paginationElemnts = (dataArray: IntraStatement[], currentPage: number, changePage: (i: number) => void): JSX.Element[] => {
    let pages: JSX.Element[] = [];
    for (let i = currentPage - 1; i < (currentPage + 2); i++) {
        if (i > 0) {
            const element = <li key={i.toString()} className={"page-item" + (i === currentPage ? " active" : "")} ><button className="btn btn-link" onClick={e => changePage(i)}>{i}</button></li>;
            pages.push(element);
        }
        if (i === currentPage && dataArray.length < 10) break;
    }
    return pages;
}


export const exportData = async (filters, t) => {
    await getIntraStatements({ ...filters, size: Number.MAX_VALUE }, (success, data) => {
        if(success && data){
            const fields = {
                postDate: t("inputs.statements.postDate"),
                channel: t("inputs.statements.channel"),
                refNum: t("inputs.statements.refNum"),
                narr1: t("inputs.statements.narr1"),
                narr2: t("inputs.statements.narr2"),
                narr3: t("inputs.statements.narr3"),
                partTrnType: t("inputs.statements.partTrnType"),
                transactionAmount: t("inputs.statements.transactionAmount"),
                closingBalanceAmount: t("inputs.statements.closingBalanceAmount"),
            }
            const newData = [];
            data?.map((item: IntraStatement) => {
                return newData.push({
                    postDate: item.postDate,
                    channel: item.channel,
                    refNum: item.refNum,
                    narr1: item.narr1,
                    narr2: item.narr2,
                    narr3: item.narr3,
                    partTrnType: item.partTrnType,
                    transactionAmount: item.transactionAmount,
                    closingBalanceAmount: item.closingBalanceAmount,
                });
            });
    
            const fileName = 'Statements-';
            const exportType = exportFromJSON.types.csv;
            exportFromJSON({ data: newData, fileName, exportType, fields, withBOM: true });
        }
    });
}