import exportFromJSON from 'export-from-json';
import { NavigateFunction } from 'react-router-dom';
import { setErrorMsg } from '../../../redux/reducers/settingsSlice';
import { CustomersTracker } from '../../CustomersTracker/CustomersTracker';
import { getAllMarkers } from '../../CustomersTracker/hook/API';
import { geoMarker } from '../../CustomersTracker/hook/ScreenHelper';
import { store } from './../../../redux/store';
import { deactivateCustomer, getAllCustomers, updateCustomerBlockStatus } from './API';

export interface filters {
    date?: string;
    bank?: string;
    status?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    loadDeactivatedCustomersOnly?: boolean;
}

export interface  Customer {
    keycloakId: string;
    id: string;
    email: string;
    phoneNumber: string;
    dateOfBirth: string;
    status: string;
    isActive: boolean;
    isBlocked: boolean;
    govId: string;
    createdAt: string;
    modifiedAt: string;
    allowNotification: boolean;
    disableLoans: boolean;
    lang: string;
    nextAttemptTime: string;
    deactivatedAt: string;
    deactivationReason: string;
    fullName : string;
}

export interface CustomerBlockStatusRequestData {
    customerId: string;
    isBlocked: string
    reason?: string;
}

// we calling this function when we need to get all records fromdatabase
export const callRequest  = async (setData: React.Dispatch<React.SetStateAction<Customer[]>>, filters: filters, initFilters?: filters) => {
    setData([]);
    await getAllCustomers(initFilters ?? filters, (_, data) => setData(data)); 
    
}

// Printing pagination items 
export const paginationElemnts = (dataArray: Customer[], currentPage: number, changePage: (i: number) => void): JSX.Element[] => {
    let pages: JSX.Element[] = [];
    for (let i = currentPage-1; i < (currentPage+2); i++) {
        if (i > 0) {
            const element = <li key={i.toString()} className={"page-item" + (i === currentPage ? " active" : "")} ><button className="btn btn-link" onClick={e => changePage(i)}>{i}</button></li>;
            pages.push(element);
        }
        if (i === currentPage && dataArray.length < 10) break;
    }
    return pages;
}

// Open deactivate popup
export const deactivateCustomerPopup = (govId: string, refresshData: () => void) => {
    let reason = "";
    store.dispatch(setErrorMsg({
        icon: null,
        cancelBtnTitle: "Cancel",
        acceptBtnTitle: 'Deactivate',
        acceptBtnAction: () => {
            deactivateCustomer(govId, reason, (success) => {
                if(success) 
                    refresshData();
            })
        },
        title: "Deactivate Customer",
        message: (<div className="mb-3" style={{ textAlign: 'start' }}>
                    <label htmlFor="recipient-name" className="col-form-label">Reason:</label>
                    <input type="text" className="form-control" placeholder='Enter reason' onChange={e => {reason = e.target.value}} />
                </div>)
    }))
}

// Open block/unblock popup
export const blockCustomerPopup = (customerBlockRequestData: CustomerBlockStatusRequestData, refresshData: () => void) => {
    let reason = "";
    let blockLabel = customerBlockRequestData.isBlocked ? 'Block' : 'Unblock';
    let blockTitle = customerBlockRequestData.isBlocked ? 'Block customer' : 'Unblock customer';
    store.dispatch(setErrorMsg({
        icon: null,
        cancelBtnTitle: "Cancel",
        acceptBtnTitle: blockLabel,
        acceptBtnAction: () => {
            customerBlockRequestData.reason = reason;
            updateCustomerBlockStatus(customerBlockRequestData, (success)  => {
                if(success) 
                refresshData();
            });
        },
        title: blockTitle,
        message: (<div className="mb-3" style={{ textAlign: 'start' }}>
                    <label htmlFor="recipient-name" className="col-form-label">Reason:</label>
                    <input type="text" className="form-control" placeholder='Enter reason' onChange={e => {reason = e.target.value}} />
                </div>)
    }))
}



// Open details popup
export const showDetails = (t, customer: Customer, navigate: NavigateFunction) => {
    getAllMarkers(customer.keycloakId, 0, (success, data) => {
        if (success) {
            store.dispatch(setErrorMsg({
                icon: null,
                title: "Customer Details",
                message:(<div className="scroller-area" style={{ maxHeight: 550, padding: 20 }}>
                            <div className="row">
                                {printElements(t, customer).map(item => item)}
                                <div className='col-12'><h5>Customer last tracking details</h5></div>
                                {data[0] ? printElements(t, data[0]).map(item => item) : <div>Customer tracking data is not available</div>}
                            </div>
                        </div>)
            }))
        }
    })
}

// Open Customer tracking map popup
export const showCustomerTrackingPopup = (customer: Customer, navigate: NavigateFunction) => {
    getAllMarkers(customer.keycloakId, 0, (success, data) => {
        if (success) {
            const points = convertAPIResponseToCoordinates(data);
            let payload = { data, points };
            store.dispatch(setErrorMsg({
                icon: null,
                cancelBtnTitle: points.length ? "Full map" : "",
                cancelBtnAction: () => {
                    navigate("/customers/map", { state: payload })
                },
                title: "Customer map",
                message: (<CustomersTracker locationData={payload}/>)
            }))
        }
    });
}

const printElements = (t, dataArray: any): JSX.Element[] => {
    const array: JSX.Element[] = [];
    console.log(dataArray)
    Object.keys(dataArray).forEach((key) => {
        array.push(<div className="col-12 col-md-6" key={key}>
                        <div className="bordered-box">
                            <strong>{t("inputs.customers." + key).toString()}</strong>
                            <span className='ms-2'>{(dataArray[key] === "" || dataArray[key] === false || dataArray[key] === undefined) ?  "-" : dataArray[key].toString()}</span>
                        </div>
                    </div>)
    });
    return array;
}
const convertAPIResponseToCoordinates = (allData: geoMarker[]): {lat: number, lng: number}[] => {
    const points: {lat: number, lng: number}[] = [];
    allData.forEach(element => {
        points.push({
            lat: Number(element.latitude),
            lng: Number(element.longitude)
        })
    })
    return points;
}

export const exportData = async (filters, t) => {
    await getAllCustomers({...filters, pageSize: Number.MAX_VALUE}, (success, data) => {
        if (success && data) {
            const fields = {
                modifiedAt: t("inputs.modifiedAt"),
                fullName: t("inputs.customers.fullName"),
                customerId: t("inputs.customerId"),
                email: t("inputs.customers.email"),
                phoneNumber: t("inputs.customers.phoneNumber"),
                dateOfBirth: t("inputs.customers.dateOfBirth"),
                isActive: t("inputs.customers.isActive"),
                status: t("inputs.status")
            }
            const newData = [];
            data?.map((item: any) => {
                return newData.push({
                    modifiedAt: item.modifiedAt,
                    fullName: item.fullName,
                    customerId: item.govId,
                    email: item.email,
                    phoneNumber: item.phoneNumber,
                    dateOfBirth: item.dateOfBirth,
                    isActive: item.isActive,
                    status: item.status
                });
            });

            const fileName = 'Customers-';
            const exportType = exportFromJSON.types.csv;
            exportFromJSON({ data: newData, fileName, exportType, fields, withBOM: true });
        }
    });
}

export const exportToExcel = (data, reportName)=>{
    let fields = Object.keys(data[0])
    const fileName = reportName;
    const exportType =  exportFromJSON.types.csv;
    exportFromJSON({data: data, fileName, exportType, fields, withBOM: true });
}





