import API from '../hooks/api';

export interface Bank {
    id: number,
    englishName: string,
    arabicName: string,
    iconLink: string,
    bic: string
}

export const getBanksList = async (
        callBack: (success: boolean, data: Bank[], errorMsg: string | null) => void = () => {}
    ) => {
    await API.get("/banks/v2")
    .then((res) => {
        callBack(true, res.data, null);
        return res.data as Bank[];
    }).catch((err) => {
        callBack(false, [], err);
        return [];
    });
}
