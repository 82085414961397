import { Roles, RouterPath } from "../../utils/Constants";

interface NavRoute {
    name: string;
    url: string;
}

type lists = {
    role: Roles;
    routes: NavRoute[];
};

const routesList: lists[] = [];
addRouteIfNotExists({
    role: Roles.operation, routes: [
        { name: 'home', url: '/' },
    ]
});

addRouteIfNotExists({
    role: Roles.anti_fraud, routes: [
        { name: 'customers', url: RouterPath.CUSTOMER_ROUTE },
        { name: 'reports', url: RouterPath.REPORTS_ROUTE },
    ]
});

addRouteIfNotExists({
    role: Roles.manager, routes: [
        { name: 'App Settings', url: RouterPath.APP_SETTINGS_ROUTE },
        // {name: 'loans', url: RouterPath.LOANS_ROUTE}
    ]
});

addRouteIfNotExists({
    role: Roles.finance, routes: [
        { name: 'payouts-finance', url: RouterPath.PAYOUT_FINANCE_ROUTE },
        { name: 'statements', url: RouterPath.STATEMENTS_ROUTE },
    ]
});
addRouteIfNotExists({
    role: Roles.credit, routes: [
        { name: 'payouts-credit', url: RouterPath.PAYOUT_CREDIT_ROUTE },
    ]
});
addRouteIfNotExists({
    role: Roles.help_desk, routes: [
        { name: 'dashboard', url: RouterPath.DASH_REPORT },
        { name: 'loans', url: RouterPath.LOANS_ROUTE }
    ]
});
addRouteIfNotExists({
    role: Roles.customer_care, routes: [
        { name: 'loans', url: RouterPath.LOANS_ROUTE },
        { name: 'dashboard', url: RouterPath.DASH_REPORT },

    ]
});

function addRouteIfNotExists(roleDatails: lists) {
    const index = routesList.findIndex((item) => item.routes.find((item) => 
            roleDatails.routes.find((incoming) => incoming.name === item.name)));
    if (index === -1) {
        routesList.push(roleDatails)
    }
}


export { routesList };
export type { lists, NavRoute };

