import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo.svg';
import { localStorageHelper } from '../../hooks/localStorageHelper';
import { NavbarItems } from '../Navbar/NavbarItems';


export const Header: React.FC = () => {
    const { i18n } = useTranslation();
    
    const onChangeLang = () => {  
        const langString = i18n.language === "en" ? "ar" : "en";
        document.body.className = (langString === "en") ? 'ltr' : 'rtl';
        i18n.changeLanguage(langString);
        console.log(i18n.t('inputs.customer'));
    }

    const logout = () => {
        localStorageHelper.remove('profile');
        window.location.reload();
    }

  return (
    <header>
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container">
                <a href="/" className="navbar-brand">
                    <img src={Logo} alt=""/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-start" id="navbarNav">
                    <NavbarItems />
                </div>
                
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <button className="btn btn-link nav-link active" aria-current="page" onClick={() => onChangeLang()}>
                                {i18n.language === "en" ? "العربية" : "English"}
                            </button>
                        </li>
                        
                        <li className="nav-item">
                            <button className="btn btn-link nav-link active" aria-current="page" onClick={() => logout()}>{t("logout").toString()}</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
  );
}

