import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportSummaryModel } from "../../pages/Reports/hooks/Screenhelper";

/**
 * ReportState
 */
export interface ReportState {
    reportSummary: ReportSummaryModel;
}

// Initial state
const initialState: ReportState = {
    reportSummary: {}
}

const reportsSlice = createSlice({
    initialState,
    name: "reports",
    reducers: {
        setReportSummary(state, action: PayloadAction<ReportSummaryModel | null>) {
            state.reportSummary = action.payload;
        }
    }
});


export const { setReportSummary } = reportsSlice.actions;
export default reportsSlice.reducer;