/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { hasRole } from '../../utils/Helpers';
import { routesList } from './routes';

export const NavbarItems: React.FC = () => {
    const navigation = useNavigate();
    const locationObj = useLocation();
    const selectedRoute = locationObj.pathname;

    const list = (): JSX.Element[] => {
        const arr: JSX.Element[] = [];
        routesList.map((item) => 
            (hasRole(item.role)) &&
                item.routes.map((routes) => 
                    arr.push(<li key={routes.name} className="nav-item">
                        <button className={selectedRoute === routes.url ? 'btn btn-link nav-link selected-btn' : 'btn btn-link nav-link'} aria-current="page" onClick={() => navigation(routes.url)}>{t(routes.name).toString()}</button>
                    </li>)
                )
        )
        return arr;
    }

    return (
        <ul className="navbar-nav">
            {list()}
        </ul>
    );
}

