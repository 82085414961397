import React from 'react';
import './Loader.css';
export const Loader: React.FC = () => {

  return (
    <div className='loader-container'>
        <div className="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
  );
}

