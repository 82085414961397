/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export const Footer: React.FC = () => {

    return (
        <>
            <footer className="container mt-auto">
                <p className="float-end">
                    <a href="#"> Back to top </a>
                </p>
                <p>© 2022 Quara Finance, Inc.</p>
            </footer>
        </>
    );
}

