import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import "./index.css";
import { ICardModel } from "./ScreenHelper";

ChartJS.register(...registerables);

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    ticks: { display: false },
    scales: {
      y: {
        min: -1,
        max: 25,
        ticks: {
          stepSize: 1,
        },
      },
    },
  },
};

const legend = {
  display: true,
  position: "bottom",
  labels: {
    fontColor: "#323130",
    fontSize: 14
  }
};



const chartOptionsExceptions = {
  plugins: {
    ticks: { display: false },
    scales: {
      y: {
        min: -1,
        max: 25,
        ticks: {
          stepSize: 1,
        },
      },
    },
  },
};

const Card = (props: ICardModel) => {
  const {count, title, incrementPer, data } = props.cardModel;
  console.log(data)
  const activeUserData = {
    labels: data?.hourly?.map((el) => el.time),
    datasets: [
      {
        label: "Active Users",
        data: data?.hourly?.map((el) => el.count),
        backgroundColor: "#2B3467",
        borderRadius: 4,
      },
    ],
  };

  const activeUserDataLine = {
    labels: ["10", "11", "12", "13", "14", "15", "16"],
    datasets: [
      {
        label: "Payment Count",
        data: [28, 48, 40, 19, 86, 89, 70],
        backgroundColor: "#2B3467",
        borderRadius: 4,
      },
    ],
  };

  const RegistrationData = {
    labels: ["10", "11", "12", "13", "14", "15", "16"],
    datasets: [
      {
        label: "Incomplete",
        backgroundColor: "#2B3467",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [28, 48, 40, 19, 86, 89, 70],
        borderRadius: 3,
      },
      {
        label: "Completed",
        backgroundColor: "#2F58CD",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [21, 89, 43, 99, 12, 29, 76],
        borderRadius: 3,
      },
    ],
  };

  const loanCountData = {
    labels: ["10", "11", "12", "13", "14", "15", "16"],
    datasets: [
      {
        label: "Completed",
        backgroundColor: "#2B3467",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [28, 48, 40, 19, 86, 89, 70],
        borderRadius: 3,
      },
      {
        label: "Pending",
        backgroundColor: "#2F58CD",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [21, 89, 43, 99, 12, 29, 76],
        borderRadius: 3,
      },
      {
        label: "Rejected",
        backgroundColor: "#4E31AA",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [10, 11, 65, 98, 12, 26, 71],
        borderRadius: 3,
      },
    ],
  };

  const loanCategoryData = {
    labels: ["10", "11", "12", "13", "14", "15", "16"],
    datasets: [
      {
        label: "CREATED",
        // backgroundColor: "#ECF2FF",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        data: [28, 48, 40, 19, 86, 89, 70],
        borderRadius: 3,
      },
      {
        label: "VERIFIED",
        backgroundColor: "#3E54AC",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [21, 89, 43, 99, 12, 29, 76],
        borderRadius: 3,
      },
      {
        label: "COMMODITY_PURCHASED",
        backgroundColor: "#655DBB",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [10, 11, 65, 98, 12, 26, 71],
        borderRadius: 3,
      },
      {
        label: "APP_CUSTCARED",
        backgroundColor: "#3A1078",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [28, 48, 40, 19, 86, 89, 70],
        borderRadius: 3,
      },
      {
        label: "CALL_INITIALIZED",
        backgroundColor: "#2F58CD",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [21, 89, 43, 99, 12, 29, 76],
        borderRadius: 3,
      },
      {
        label: "CALL_APPROVED",
        backgroundColor: "#A61F69",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [10, 11, 65, 98, 12, 26, 71],
        borderRadius: 3,
      },
      {
        label: "NAFAES_SALES_ORDERED",
        backgroundColor: "#D9ACF5",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [28, 48, 40, 19, 86, 89, 70],
        borderRadius: 3,
      },
      {
        label: "CONTRACT_SIGNED",
        backgroundColor: "#5D3891",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [21, 89, 43, 99, 12, 29, 76],
        borderRadius: 3,
      },
      {
        label: "SANAD_CREATED",
        backgroundColor: "#6096B4",
        // borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.6)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [10, 11, 65, 98, 12, 26, 71],
        borderRadius: 3,
      },
    ],
  };

  const datas = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774"
      }
    ]
  };

  const ChartRender = () => {
    if (title === "Login Counts")
      return <Bar data={activeUserData} options={chartOptions} />;
    if (title === "Payment Counts")
      return <Line data={activeUserDataLine} options={chartOptions} />;
    if (title === "Registration")
      return <Bar data={RegistrationData} options={chartOptions} />;
    if (title === "Loan Count")
      return <Bar data={loanCountData} options={chartOptions} />;
    if (title === "Loan Category")
      return <Line data={loanCategoryData}/>;
  };

  return (
    <div className="card graph_Card">
      <div className="card-body">
        <div className="row">
          <span className="col-8 text-left">
            <h6>{title}</h6>
            <h5 className="dashBoard_header">{count??0}</h5>
          </span>
          <div className="col-4">
            <span className="text-right">{incrementPer??0}%</span>
            <span style={{ margin: "5px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-arrow-up-circle-fill"
                viewBox="0 0 16 16"
                style={{ color: incrementPer < 100 ? "red" : "green" }}
              >
                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
              </svg>
            </span>
            <h6 className="minor_header">vs Last Week</h6>
          </div>
        </div>
        <ChartRender />
      </div>
    </div>
  );
};

export default Card;
