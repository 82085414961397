import { Bank } from '../../../main_apis/GetBanksList';
import exportFromJSON from 'export-from-json';
import { getAllIbanRequests } from './API'

export interface filters {
    date?: string;
    bank?: string;
    status?: string;
    page?: number;
    search?: string;
    size?: number;
    includeRejectedLoans?: boolean;
}


export interface CustomerRequest {
    requestId: string;
    customerId?: string;
    customerName?: string;
    arabicName: string;
    bankName?: string;
    iban?: string;
    bankCertificateUrl?: string;
    requestedAt?: string;
    createdAt?: string;
    modifiedAt?: string;
    ibanStatus?: IbanStatusType;
}
export interface IbanRequestData {
    reqId: string;
    status: IbanStatusType;
}

export type IbanStatusType = IbanStatus.ALL | IbanStatus.VERIFIED | IbanStatus.REJECTED | IbanStatus.TO_BE_VERIFIED;
export enum IbanStatus {
    TO_BE_VERIFIED = 'TO_BE_VERIFIED',
    VERIFIED = 'VERIFIED',
    REJECTED = 'REJECTED',
    ALL = 'TO_BE_VERIFIED,VERIFIED,REJECTED'
}

export const sortBanks = (banks, i18n): Bank[] => {
    return i18n.language === "ar" ?
        (banks.sort((a, b) => a.arabicName > b.arabicName ? 1 : -1)) :
        (banks.sort((a, b) => a.englishName > b.englishName ? 1 : -1))
}

export const exportData = async(filters, t) => {

    await getAllIbanRequests({...filters, size: Number.MAX_VALUE}, (success, data) => {
        if (success && data) {
            const fields = { createdAt: t("inputs.date"), 
            customerName: t("inputs.customer"), 
            customerId: t("inputs.customerId"), 
            bankName: t("inputs.bank"), 
            iban: t("inputs.iban"), 
            modifiedAt: t("inputs.modifiedAt"), 
            ibanStatus: t("inputs.status") }
            const newData = [];
            data.map((item: any) => {
                return newData.push({
                    createdAt: item.createdAt,
                    customerName: item.customerName,
                    customerId: item.customerId,
                    bankName: item.bankName,
                    iban: item.iban,
                    modifiedAt: item.modifiedAt,
                    ibanStatus: item.ibanStatus
                });
            });

            const fileName = 'IBAN-';
            const exportType = exportFromJSON.types.csv;
            exportFromJSON({ data: newData, fileName, exportType, fields, withBOM: true });
        }
    });
}