import API from '../../../hooks/api';
import { getFullURL } from '../../../utils/Endpoints';


export const getStatsData = async (url: string) => {
    try {
        const response = await API.get(getFullURL(url))
        let { data } = await response
        return data
    } catch (error) {
        console.log(error)
    }
}