import React, { ForwardRefRenderFunction } from 'react';
import { setErrorMsg } from '../../redux/reducers/settingsSlice';
import { store } from '../../redux/store';

interface IProps {
  show: boolean;
  closeBtn?: boolean;
  modalContentClass?: string;
  icon?: boolean | null | undefined;
  title: string | null | undefined;
  errorMsg?: string | JSX.Element | null;

  acceptBtnTitle?: string | JSX.Element;
  acceptBtnOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  cancelBtnTitle?: string | JSX.Element | null;
  cancelBtnOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Alert: ForwardRefRenderFunction<HTMLInputElement, IProps> = 
          ({ show, closeBtn, title, errorMsg,acceptBtnTitle, modalContentClass,
            acceptBtnOnClick, cancelBtnTitle, cancelBtnOnClick, icon, ...otherProps }) => {
  return (
    <div className={"custom-modal" + (show ? ' show-modal' : '')}>
      <div className={"modal-content " + modalContentClass }>
          {icon && <div className="icon">
              <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="42.5" cy="42.5" r="42.5" fill="#FFE3E5"/>
                  <path d="M31.4375 53.2707L53.2696 31.4386" stroke="#D02631" strokeWidth="9" stroke-linecap="round" strokeLinejoin="round"/>
                  <path d="M53.2696 53.2707L31.4375 31.4386" stroke="#D02631" strokeWidth="9" stroke-linecap="round" strokeLinejoin="round"/>
              </svg>
          </div>}
          <h1 className="title">{title} 
          {closeBtn && <span className="close-button float-end" onClick={() => store.dispatch(setErrorMsg({title: null}))}>×</span>}
          </h1>
          { errorMsg !== null && (<p className="description">{errorMsg}</p>)}
          <div className="modal-footer">
              {acceptBtnTitle && <button type="button" className="btn btn-warning btn-lg mb-0" onClick={acceptBtnOnClick}>{acceptBtnTitle}</button>}
              {cancelBtnTitle && <button type="button" className="btn btn-secondary btn-lg mb-0" onClick={cancelBtnOnClick}>{cancelBtnTitle}</button>}
          </div>
      </div>
  </div>
  );
}

