  import './App.css';
  import 'jquery/dist/jquery.min.js';
  import 'bootstrap/dist/js/bootstrap.min.js';
  import './assets/custom.scss';
  import 'moment-timezone';

  import { MainLayout } from './layouts/MainLayout';
  import { BrowserRouter} from 'react-router-dom';
  import { useEffect } from 'react';
  import { useTranslation } from 'react-i18next';
  import { localStorageHelper } from './hooks/localStorageHelper';
  import { Auth } from './routers/Auth.router';
  import { Alert } from './components/Alert/Alert';
  import { useAppSelector } from './hooks/hooks';
  import { setErrorMsg } from './redux/reducers/settingsSlice';
  import { store } from './redux/store';
  import { Loader } from './components/Loader/Loader';
  import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
  import "primereact/resources/primereact.min.css";                  //core css
  import "primeicons/primeicons.css";  

  function App() {
    const { i18n } = useTranslation();
    const isLoggedIn: string | undefined = localStorageHelper.load('profile')?.access_token;
    const {loading, error} = useAppSelector(state => state.settings);
    useEffect(()=> {
      document.body.className = (i18n.language === "en") ? 'ltr' : 'rtl';
    });
    
    return (
      <BrowserRouter>
        {loading ? <Loader /> : null}
        {!isLoggedIn ? 
          <Auth /> :
          <MainLayout />
        }
        <Alert
            show={error?.title != null}
            icon={error.icon == null ? null : error.icon}
            title={error?.title}
            closeBtn={true}
            modalContentClass={error?.modalContentClass}
            errorMsg={error?.message}
            acceptBtnTitle={error?.acceptBtnTitle ?? "Exit"}
            acceptBtnOnClick={() => {
                if (typeof error?.acceptBtnAction !== "undefined") {
                  error?.acceptBtnAction()
                }
                store.dispatch(setErrorMsg({title: null}))
            }}
            cancelBtnTitle={error?.cancelBtnTitle}
            cancelBtnOnClick={() => {
                if (typeof error?.cancelBtnAction !== "undefined") {
                  error?.cancelBtnAction()
                }
                store.dispatch(setErrorMsg({title: null}))
            }}
        />
      </BrowserRouter>
    );
  }

  export default App;
