import { getAllCustomers } from "../../Customers/hook/API";
import { Customer } from "../../Customers/hook/ScreenHelper";
import { getRejectedLoans, getRejectedRegistration } from "./API";

export interface ReportSummaryModel {
    registrationDiffCount?: number;
    deactivationReportCount?: number;
    loanDiffCount?: number;
}

/**
 * Customer Tracking Info Model
 */
export interface CustomerTrackingInfoModel {
    keycloakId: string;
    id: string;
    city: string;
    country: string;
    deviceName: string;
    deviceId: string;
    latitude: string;
    longitude: string;
    phoneIpAddress: string;
    operationType: string;
    createdAt: string;
    customerId: string;
}


export interface ReportFilter {
    page?: number;
    search?: string;
}


export const callLoanRejectionRequest = async (setData: React.Dispatch<React.SetStateAction<CustomerTrackingInfoModel[]>>, filters: ReportFilter) => {
    setData([]);
    await getRejectedLoans(filters, (_, data) => setData(data || []));
}

export const callRegistrtaionRejectionRequest = async (setData: React.Dispatch<React.SetStateAction<CustomerTrackingInfoModel[]>>, filters: ReportFilter) => {
    setData([]);
    await getRejectedRegistration(filters, (_, data) => setData(data || []));
}

export const callRegistrationRejectionRequest = async (setData: React.Dispatch<React.SetStateAction<Customer[]>>, filters: ReportFilter) => {
    setData([]);
    await getAllCustomers(filters, (_, data) => setData(data || []));
}