import { AxiosResponse } from 'axios';
import API from '../../../hooks/api';
import { IbanStatusType } from '../../Dashboard/hook/ScreenHelper';
import { IbanUpdateRequestModel } from './ScreenHelper';

export const updateStatus = async (
    ibanPayload: IbanUpdateRequestModel,
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
    await API.put("/iban-operation/request/update", ibanPayload)
    .then((res) => {
        callBack(true, null);
        return res.data;
    }).catch((err) => {
        callBack(false, err);
    });
}

export const getCertificate = async (
    requestId: string,
    callBack: (success: boolean, data: AxiosResponse | null, errorMsg: string | null) => void = () => {}
) => {
    await API.get("/iban-operation/certificate/view/"+requestId)
    .then((res) => {
        callBack(true, res, null);
        return res.data;
    }).catch((err) => {
        callBack(false, null, err);
    });
}

