import { configureStore } from "@reduxjs/toolkit";
import settingsSlice from "./reducers/settingsSlice";
import reportsSlice from "./reducers/reportsSlice";
// import AuthReducer from './features/authSlice';

export const store = configureStore({
  reducer: {
    settings: settingsSlice,
    reports: reportsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
