import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Pagination";
import { useAppSelector } from "../../../hooks/hooks";
import { exportToExcel } from "../../Customers/hook/ScreenHelper";
import {
  callRegistrtaionRejectionRequest,
  CustomerTrackingInfoModel,
  ReportFilter
} from "../hooks/Screenhelper";

export const SignupReport: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<CustomerTrackingInfoModel[]>([]);
  const [filter, setFilter] = useState<ReportFilter>({ page: 1 });
  const { reportSummary } = useAppSelector((state) => state.reports);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    callRegistrtaionRejectionRequest(setData, filter);
  }, [page, filter]);

  const changePage = (currentPage: number) => {
    setFilter({ ...filter, page: currentPage });
    setPage(currentPage);
  };

  return (
    <>
      <div className="box_container">
        <h3 className="text-center">
        {t("pages.signupReport.signup")}
          <span className="mx-2 badge bg-success">
            {reportSummary.registrationDiffCount}
          </span>
          <button
            className="btn bg-gradient-dark mx-2 badge bg-success"
            style={{ padding: "10px" }}
            onClick={()=>exportToExcel(data, "LoanReport")}
            type="button"
            data-bs-toggle="offcanvas"
            aria-controls="offcanvasExample"
            data-bs-placement="top"
            title="Download"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </button>
        </h3>

        <div className="col-md-12">
          <div className=" table-responsive">
            <table className="table table-hover my-table">
              <thead>
                <tr>
                  <th scope="col">Date & time</th>
                  <th scope="col">User IP address</th>
                  <th scope="col">Country</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.createdAt}</td>
                      <td>{item.phoneIpAddress}</td>
                      <td>{item.country}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination data={data} changepage={changePage} page={page}/>
        </div>
      </div>
    </>
  );
};
