import React from 'react';

export const TableRowPlaceholder: React.FC = () => {

    return (
        <>
            <tr className="placeholder-glow" key={0}><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td></tr>
            <tr className="placeholder-glow" key={1}><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td></tr>
            <tr className="placeholder-glow" key={2}><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td></tr>
            <tr className="placeholder-glow" key={3}><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td><td><span className="placeholder col-7"></span></td></tr>
        </>
    );
}

