import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../hooks/hooks";
import { setReportSummary } from "../../redux/reducers/reportsSlice";
import { store } from '../../redux/store';
import { getRejectionTrackingSummary } from "./hooks/API";
import { ReportSummaryModel } from "./hooks/Screenhelper";

export const Reports: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { reportSummary } = useAppSelector(state => state.reports);

    useEffect(() => {
        getRejectionTrackingSummary(
            (success: boolean, data: ReportSummaryModel) => {
                if (success) {
                    store.dispatch(setReportSummary(data));
                }
            }
        );
    }, []);

    const handleReport = (report: string) => {
        switch (report) {
            case "signup":
                navigate("/reports/signup");
                break;
            case "deactivation":
                navigate("/reports/deactivation");
                break;
            case "loan":
                navigate("/reports/loan");
                break;
            default:
                break;
        }
    };

    

    return (
        <>
            <div className="box_container">
                <div className="d-flex row flex-row flex-wrap">
                    <div className="report-card report-card-bottom-primary m-2 p-2" onClick={() => handleReport('signup')}>
                            <h2 className="text-white text-center  mt-3">{reportSummary.registrationDiffCount}</h2>
                            <p className="text-white text-center">{t("pages.signupReport.signup")}</p>
                    </div>

                    <div className="report-card report-card-bottom-secondary m-2 p-2" onClick={() => handleReport('deactivation')}>
                        <h2 className="text-white text-center ms-4 mt-3">{reportSummary.deactivationReportCount}</h2>
                        <p className="text-white text-center ms-4">{t("pages.deactivationReport.deactivation")}</p>
                    </div>

                    <div className="report-card report-card-bottom-success m-2 p-2" onClick={() => handleReport('loan')}>
                        <h2 className="text-white text-center  ms-4 mt-3" >{reportSummary.loanDiffCount}</h2>
                        <p className="text-white text-center  ms-4">{t("pages.loanReport.loanApp")}</p>
                    </div>
                </div>
            </div>
        </>
    )
}