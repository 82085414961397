/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Search from '../../assets/images/icon-search.svg';
import Arrow from '../../assets/images/pagination_arrow.svg';
import { TableRowPlaceholder } from '../../components/TableRowPlaceholder/TableRowPlaceholder';
import { useAppSelector } from '../../hooks/hooks';
import { ALL } from '../../utils/Constants';
import { callRequest, cashLoanStages, exportCreditAPIData, filters, LoanLookUpInfo, loanStages, paginationElemnts, exportData } from './hook/ScreenHelper';
import { CSVLink } from "react-csv";
import Moment from 'react-moment';
import { Accordion, AccordionTab } from 'primereact/accordion';
        

export const Loans: React.FC = () => {
    const { t } = useTranslation();
    const { loading } = useAppSelector(state => state.settings);

    const [page, setPage] = useState<number>(1);
    const [inputs, setInputs] = useState<filters>({ page });
    const [filters, setFilters] = useState<filters>({ page, requestType: 'ONGOING', stage: ALL });
    const [data, setData] = useState<LoanLookUpInfo[]>([]);
    const [timer, setTimer] = useState(null);

    const fileHeaders = [
        { label: t("inputs.loans.createdAt"), key: 'createdAt' },
        { label: t("inputs.loans.customerName"), key: 'customerName' },
        { label: t("inputs.loans.nationalId"), key: 'nationalId' },
        { label: t("inputs.loans.financialDataId"), key: 'financialDataId' },
        { label: t("inputs.loans.compAppId"), key: 'compApplId' },
        { label: t("inputs.loans.lastCashLoanStage"), key: 'lastCashLoanStage' },
        { label: t("inputs.loans.reason"), key: 'reason' },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { callRequest(setData, filters) }, [page]);

    const changePage = (currentPage: number) => {
        setFilters({ ...filters, page: currentPage });
        setPage(currentPage);
    }

    const searchInputChanged = value => {
        setInputs({ ...inputs, search: value });
        setFilters({ ...filters, search: value });

        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            callRequest(setData, filters, { ...filters, search: value });
        }, 500);

        setTimer(newTimer)
    }


    const changeRequestType = (data) => {
        //If the request type is the same as the current one, do nothing
        if (data === filters.requestType) { 
            return; 
        }
        let filterPayload = { ...filters, requestType: data };
        setFilters(filterPayload);
        callRequest(setData, filterPayload);
    }

    const changeLoanStage = (data) => {
        //If the stage is the same as the current one, do nothing
        if (data === filters.stage) {
            return;
        }
        let filterPayload = { ...filters, stage: data };
        setFilters(filterPayload);
        callRequest(setData, filterPayload);
    }
    const currentDate = new Date().toISOString().substring(0, 10);
    const fileName = "Loans-"+ currentDate + ".csv";

    return (
        <>
            <div className="box_container">
                <div className="row g-3">
                    <div className="col-md-12">
                        <div className="table-header">
                            <span className='ms-auto'>
                                <button className="btn bg-gradient-dark" type="button" onClick={() => exportCreditAPIData(t)} title="Download credit API" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                    </svg>
                                    <span className='mx-1'>{t("pages.loans.creditQualityData")}</span>
                                </button>
                            </span>
                        </div>
                        <div className="row table-header">
                            <div className="col-12 col-md-12">
                                <div className="navbar navbar-expand-lg  " >
                                    <div className="container-fluid">
                                        <div className="input-group search-input align-item-center">
                                            <span className="input-group-text"><img alt="" src={Search} /></span>
                                            <input type="text" className="form-select" placeholder={t("inputs.loans.searchInputPlaceHolder")} onChange={e => searchInputChanged(e.target.value)} value={inputs.search} />
                                            {inputs.search && <button type="button" className="btn-close ps mx-3" aria-label="Close" onClick={() => searchInputChanged('')}></button>}
                                        </div>


                                        <div className="dropdown  margin-end">
                                            <button  className="btn bg-gradient-dark dropdown-toggle" data-bs-toggle="dropdown">
                                                APP Stage ({t("pages.loans.status." + filters.requestType)})
                                            </button>
                                            <ul className="dropdown-menu dropdown-right-align">
                                                {
                                                    loanStages.map(type =>  {
                                                        return <li key={type}><a className="dropdown-item" onClick={() => changeRequestType(type)}>{t("pages.loans.status." + type)}</a></li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="dropdown ms-2 margin-end">
                                            <button  className="btn bg-gradient-dark  dropdown-toggle" data-bs-toggle="dropdown">
                                               Loan Stage  ({t("pages.loans.stage." + filters.stage)})
                                            </button>
                                            <ul className="dropdown-menu dropdown-right-align">
                                                {
                                                    cashLoanStages.map(stage =>  {
                                                        return <li key={stage}><a className="dropdown-item" onClick={() => changeLoanStage(stage)}>{t("pages.loans.stage." + stage)}</a></li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className='ms-2'>
                                            <button onClick={()=>exportData(filters,t)} className="btn" type="button" data-bs-toggle="offcanvas" aria-controls="offcanvasExample" title="Download" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-12 ">
                        <div className="table-responsive" style={{ minHeight: '25rem' }}>

                            <table className="table table-hover my-table" >
                                <thead>
                                    <tr>
                                        <th scope="col">{t("inputs.loans.createdAt")}</th>
                                        <th scope="col">{t("inputs.loans.customerName")}</th>
                                        <th scope="col">{t("inputs.loans.financialDataId")}</th>
                                        <th scope="col">{t("inputs.loans.compAppId")}</th>
                                        <th scope="col">{t("inputs.loans.lastCashLoanStage")}</th>
                                        <th scope="col">{t("inputs.loans.reason")}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <TableRowPlaceholder />
                                    )}

                                    {data.map((item, key) =>
                                        <tr key={item.createdAt}>
                                            <td><Moment format='YYYY-MM-DD HH:mm:ss' tz="Asia/Riyadh">{item.createdAt}</Moment></td>
                                            <td>
                                                <strong>{item.customerName}</strong>
                                                <p className="gray"><strong>{item.nationalId}</strong></p>
                                            </td>
                                            <td>{item.financialDataId}</td>
                                            <td>{item.compApplId}</td>
                                            <td>{t("pages.loans.stage." + item.lastCashLoanStage)}</td>
                                            <td>{item.reason}</td>

                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 ">
                            <div className="table-footer">
                                <div className="results-count">
                                    <p></p>
                                </div>
                                <div className="results-pagination">
                                    <nav>
                                        <ul className="pagination">
                                            <li key={'9991'} className="page-item">
                                                <button className="prev page-link" disabled={page <= 1} onClick={() => changePage(page - 1)}><img alt="" src={Arrow} /></button></li>
                                            {paginationElemnts(data, page, changePage)}
                                            <li key={'9992'} className="page-item"><button className="next page-link" disabled={data.length < 10} onClick={() => changePage(page + 1)}><img alt="" src={Arrow} /></button></li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Accordion activeIndex={0}>
                            <AccordionTab header="Stage Descriptions">
                                <p className="m-0">
                                    <ol>
                                        <li><b>{t("pages.loans.stage.CREATED")} </b>: <span>{t("pages.loans.stageDescription.CREATED")}</span></li>
                                        <li><b>{t("pages.loans.stage.LOCKED")} </b>: <span>{t("pages.loans.stageDescription.LOCKED")}</span></li>
                                        <li><b>{t("pages.loans.stage.VERIFIED")} </b>: <span>{t("pages.loans.stageDescription.VERIFIED")}</span></li>
                                        <li><b>{t("pages.loans.stage.SOFT_REJECTED")} </b>: <span>{t("pages.loans.stageDescription.SOFT_REJECTED")}</span></li>
                                        <li><b>{t("pages.loans.stage.COMMODITY_PURCHASED")} </b>: <span>{t("pages.loans.stageDescription.COMMODITY_PURCHASED")}</span></li>
                                        <li><b>{t("pages.loans.stage.APP_CUSTCARED")} </b>: <span>{t("pages.loans.stageDescription.APP_CUSTCARED")}</span></li>
                                        <li><b>{t("pages.loans.stage.CALL_INITIALIZED")} </b>: <span>{t("pages.loans.stageDescription.CALL_INITIALIZED")}</span></li>
                                        <li><b>{t("pages.loans.stage.CALL_APPROVED")} </b>: <span>{t("pages.loans.stageDescription.CALL_APPROVED")}</span></li>
                                        <li><b>{t("pages.loans.stage.CONTRACT_SIGNED")} </b>: <span>{t("pages.loans.stageDescription.CONTRACT_SIGNED")}</span></li>
                                        <li><b>{t("pages.loans.stage.SANAD_CREATED")} </b>: <span>{t("pages.loans.stageDescription.SANAD_CREATED")}</span></li>
                                        <li><b>{t("pages.loans.stage.WAITING_FOR_DISBURSED")} </b>: <span>{t("pages.loans.stageDescription.WAITING_FOR_DISBURSED")}</span></li>
                                        <li><b>{t("pages.loans.stage.NAFAES_SALES_ORDERED")} </b>: <span>{t("pages.loans.stageDescription.NAFAES_SALES_ORDERED")}</span></li>
                                        <li><b>{t("pages.loans.stage.DISBURSED")} </b>: <span>{t("pages.loans.stageDescription.DISBURSED")}</span></li>
                                        <li><b>{t("pages.loans.stage.WITHDRAWAL_REQUESTED")} </b>: <span>{t("pages.loans.stageDescription.WITHDRAWAL_REQUESTED")}</span></li>
                                        <li><b>{t("pages.loans.stage.WITHDRAWAL_SUCCESS")} </b>: <span>{t("pages.loans.stageDescription.WITHDRAWAL_SUCCESS")}</span></li>
                                        <li><b>{t("pages.loans.stage.WITHDRAWAL_FAILED")} </b>: <span>{t("pages.loans.stageDescription.WITHDRAWAL_FAILED")}</span></li>
                                        <li><b>{t("pages.loans.stage.HARD_REJECTED")} </b>: <span>{t("pages.loans.stageDescription.HARD_REJECTED")}</span></li>
                                    </ol>
                                </p>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    );
}

