import API from '../../../hooks/api';
import { geoMarker } from './ScreenHelper';
const { REACT_APP_BASE_URL } = process.env;

export const getAllMarkers = async (
        keycloakId: string,
        pageNo,
        callBack: (success: boolean, data: geoMarker[], errorMsg: string | null) => void = () => {}
    ) => {
    await API.get(REACT_APP_BASE_URL + "/operations/user-tracking/list", {params: {
        page: pageNo || 0,
        size: 10,
        keycloakId
    }})
    .then((res) => {
        const data: geoMarker[] = res.data.items;
        callBack(true, data, null);
        return res.data;
    }).catch((err) => {
        callBack(false, [], err);
    });
}
