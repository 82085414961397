/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react';
import Search from '../../assets/images/icon-search.svg';
import Arrow from '../../assets/images/pagination_arrow.svg';

import {
    callRequest, acceptPopUp, Payout, filters, paginationElemnts, showDetails, payoutProviderTypes,
    financeRoleOperations, creditRoleOperations, updatePayoutStatusPopUp, creditPayoutStatusList, financePayoutStatusList, exportData
} from './hook/ScreenHelper';
import { useAppSelector } from '../../hooks/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ALL, PaymentProvider, PayoutPageType, PayoutStatus, Roles, RouterPath } from '../../utils/Constants';
import { TableRowPlaceholder } from '../../components/TableRowPlaceholder/TableRowPlaceholder';
import { hasRole } from '../../utils/Helpers';
import Moment from 'react-moment';
import { Sidebar } from 'primereact/sidebar';
import Filter from '../../assets/images/filter.svg';

export const Payouts: React.FC = () => {
    const { t } = useTranslation();
    const { loading } = useAppSelector(state => state.settings);
    const navigate = useNavigate();
    const locationObj = useLocation();
    const [operation, setOperation] = useState<boolean>(false)

    //Check the route and roles for finance
    const payoutMetaData = useMemo(() => {
        let payoutMetaobj = {
            isPayrollFinance: false,
            isPayrollCredit: false,
            payoutStatus: ALL,
            payoutStatusList: [],
            payoutPageType: PayoutPageType.FINANCE
        }
        if (locationObj.pathname === RouterPath.PAYOUT_FINANCE_ROUTE && hasRole(Roles.finance)) {
            payoutMetaobj.isPayrollFinance = true;
            payoutMetaobj.payoutStatus = PayoutStatus.PROCESSING;
            payoutMetaobj.payoutPageType = PayoutPageType.FINANCE;
            payoutMetaobj.payoutStatusList = [...financePayoutStatusList];
        } else if (locationObj.pathname === RouterPath.PAYOUT_CREDIT_ROUTE && hasRole(Roles.credit)) {
            payoutMetaobj.isPayrollCredit = true;
            payoutMetaobj.payoutStatus = PayoutStatus.PENDING_FOR_APPROVAL;
            payoutMetaobj.payoutPageType = PayoutPageType.CREDIT;
            payoutMetaobj.payoutStatusList = [...creditPayoutStatusList];
        }
        return payoutMetaobj;
    }, [locationObj.pathname]);


    const [page, setPage] = useState<number>(1);
    const [inputs, setInputs] = useState<filters>({ page, includeRejectedLoans: false });
    const [filters, setFilters] = useState<filters>({ page, payoutProvider: ALL, 
        payoutStatus: payoutMetaData.payoutStatus, payoutPageType: payoutMetaData.payoutPageType, includeRejectedLoans: false });
    const [data, setData] = useState<Payout[]>([]);
    const [timer, setTimer] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedFinance, setSelectedFinance] = useState<Payout[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { 
        callRequest(setData, filters); 
    }, [page]);

    useEffect(()=>{
        setOperation(selectedFinance && selectedFinance.length > 0)
    },[selectedFinance])

    const changePage = (currentPage: number) => {
        setFilters({ ...filters, page: currentPage });
        setPage(currentPage);
    }

    const searchInputChanged = value => {
        setInputs({ ...inputs, search: value });
        setFilters({ ...filters, search: value });

        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            callRequest(setData, filters, { ...filters, search: value });
        }, 500);

        setTimer(newTimer)
    }

    const changePayoutProvider = (data) => {
        //If payout provider is same then return
        if(data === filters.payoutProvider){
            return;
        }
        let filterPayload = { ...filters, payoutProvider: data };
        setFilters(filterPayload);
        callRequest(setData, filterPayload);
    }

    const changePayoutStatus = (data) => {
        //If payout status is same then return
        if (data === filters.payoutStatus) {
            return;
        }
        let filterPayload = { ...filters, payoutStatus: data };
        setFilters(filterPayload);
        callRequest(setData, filterPayload);
    }

    const updateRequest = (objectId: string, payoutStatus: string) => {
        let payoutObj = { objectId, payoutStatus };
        updatePayoutStatusPopUp(payoutObj, () => {
            //Remove the updated items from selected list.
            const newArray = selectedFinance.filter(res => (res.objectId !== objectId));
            setSelectedFinance(newArray);
            callRequest(setData, filters);
        });

    }

    const updateBulkRequest = (data: Payout[], payoutStatus: PayoutStatus) => {
        let payouts = data.map(res => ({
            objectId: res.objectId,
            payoutStatus: payoutStatus,
            reasonOfRejection: ""
        }));
        const payload = {
            payouts: payouts.filter(res => res !== null)
        }
        acceptPopUp(data, payload, payoutStatus, () => {
            //Remove the updated items from selected list.
            const newArray = selectedFinance.filter(res => payload.payouts.findIndex(pay => pay.objectId === res.objectId) === -1);
            setSelectedFinance(newArray);

            callRequest(setData, filters);
        }, false);

        
    }
    
    const selectData = (e: boolean, key: number, item: Payout) => {
        if (e) {
            setSelectedFinance(prevItems => {
                const prevItemsClone = [...prevItems];
                const index = prevItemsClone.findIndex(res => res.objectId === item.objectId);
                if (index === -1) {
                    prevItemsClone.push(item);
                }
                return prevItemsClone;
            })
        } else {
            const newArray = selectedFinance.filter(res => (res.objectId !== item.objectId));
            setSelectedFinance(newArray);
        }
    }
    
    return (
        <>
            <div className="box_container">
                <div className="row g-3">
                    <div className="col-md-12">
                        <div className="row table-header">
                            <div className="col-12 col-md-12 d-flex container-fluid align-items-center">
                                <div className="input-group search-input align-items-center">
                                    <span className="input-group-text"><img alt="" src={Search} /></span>
                                    <input type="text" className="form-select" placeholder={t("search_input_placeholder")} onChange={e => searchInputChanged(e.target.value)} value={inputs.search} />
                                    {inputs.search && <button type="button" className="btn-close ps mx-3" aria-label="Close" onClick={() => searchInputChanged('')}></button>}
                                </div>

                                <div className="dropdown  margin-end">
                                    <button className="btn bg-gradient-dark mb-0 dropdown-toggle mx-2" data-bs-toggle="dropdown">
                                    {t('common.payment_method')} ({t("pages.payout.payment_methods." + filters.payoutProvider)})
                                    </button>
                                    <ul className="dropdown-menu dropdown-right-align">
                                        {
                                            payoutProviderTypes.map(stage => {
                                                return <li key={stage}><a className="dropdown-item" onClick={() => changePayoutProvider(stage)}>{t("pages.payout.payment_methods." + stage)}</a></li>
                                            })
                                        }
                                    </ul>
                                   
                                </div>
                                <div className="dropdown ms-2 margin-end">
                                    <button className="btn bg-gradient-dark mb-0 dropdown-toggle" data-bs-toggle="dropdown" title="Payoutstatus">
                                        {t("inputs.payouts.status")} ({t("pages.payout.status." + filters.payoutStatus)})
                                    </button>
                                    <ul className="dropdown-menu dropdown-right-align">
                                        {
                                            payoutMetaData.payoutStatusList.map(payoutStatus => {
                                                return <li key={payoutStatus}><a className="dropdown-item" onClick={() => changePayoutStatus(payoutStatus)}>{t("pages.payout.status." + payoutStatus)}</a></li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='ms-2 d-flex'>
                                    <button className="btn m-0 bg-gradient-dark" type="button" title="Filter" onClick={()=> setShowFilters(true)}><img alt="" src={Filter} /></button>
                                    <button className="btn m-0 bg-gradient-dark text-white ms-1" onClick={() => exportData(filters,t)} type="button" data-bs-toggle="offcanvas" aria-controls="offcanvasExample" data-bs-placement="top" title="Download">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 ">
                        {(operation && data.length && payoutMetaData.isPayrollCredit) ?
                            <div className="container p-0 d-flex justify-content-end">
                                <button title="Approve the selected records" onClick={() => updateBulkRequest(selectedFinance, PayoutStatus.APPROVED)} className="btn btn-success mx-3">{t(`inputs.payouts.statusList.APPROVED`)}</button>
                                <button title="Rejected the selected records" onClick={() => updateBulkRequest(selectedFinance, PayoutStatus.REJECTED)} className="btn btn-danger">{t(`inputs.payouts.statusList.REJECTED`)}</button>
                            </div> : null
                        }
                        {(operation && data.length && payoutMetaData.isPayrollFinance) ?
                            <div className="container p-0 d-flex justify-content-end">
                                <button title="Approve the selected records as successful" onClick={() => updateBulkRequest(selectedFinance, PayoutStatus.SUCCESSFUL)} className="btn btn-success mx-3">{t(`inputs.payouts.statusList.SUCCESSFUL`)}</button>
                            </div> : null
                        }
                        <div className="table-responsive" style={{ minHeight: '25rem' }}>
                            <table className="table table-hover my-table" >
                                <thead>
                                    <tr>
                                        <th scope="col">{t("select")}</th>
                                        <th scope="col" style={{ width: 100 }}>{t("inputs.date")}</th>
                                        <th scope="col">{t("inputs.payouts.customerName")}</th>
                                        <th scope="col">{t("inputs.payouts.bankName")}</th>
                                        <th scope="col">{t("inputs.payouts.iban")}</th>
                                        <th scope="col">{t("inputs.payouts.payoutProvider")}</th>
                                        <th scope="col">{t("inputs.payouts.compAppId")}</th>
                                        <th scope="col">{t("inputs.payouts.financialDataId")}</th>
                                        <th scope="col">{t("inputs.requestedAmount")}</th>
                                        <th scope="col">{t("inputs.amount")}</th>
                                        <th scope="col">{t("inputs.payouts.status")}</th>
                                        <th scope="col" className='table-actions-col'>{t("inputs.actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <TableRowPlaceholder />
                                    )}
                                    {data.map((item, key) =>
                                        <tr key={key}>
                                            <td>
                                                {
                                                    ((payoutMetaData.isPayrollFinance && item.payoutProvider === PaymentProvider.HYPER_PAY && item.status === PayoutStatus.PROCESSING) ||
                                                        (payoutMetaData.isPayrollCredit && item.status === PayoutStatus.PENDING_FOR_APPROVAL)) &&
                                                    <input
                                                        onChange={(e) => selectData(e.target.checked, key, item)}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexCheckDefault"
                                                        checked={selectedFinance.findIndex(res => (res.objectId === item.objectId)) != -1}
                                                    />
                                                }
                                            </td>
                                            <td><small style={{ fontSize: 10 }}><Moment format='YYYY-MM-DD HH:mm:ss' tz="Asia/Riyadh">{item.createdAt}</Moment></small></td>
                                            <td>
                                                <strong>{item.customerName}</strong>
                                                {item.arabicName ? <p className="gray"><strong>{item.arabicName}</strong></p>: null}
                                                <p className="gray"><strong>{item.nationalId}</strong></p> 
                                            </td>
                                            <td>{item.bankName}</td>
                                            <td>{item.iban}</td>
                                            <td>{t("pages.payout.payment_methods." + item.payoutProvider)}</td>
                                            <td>{item.compAppId}</td>
                                            <td>{item.financialDataId}</td>
                                            <td>{item.requestedAmount}</td>
                                            <td>{item.transferAmount}</td>
                                            <td>{t("pages.payout.status." + item.status)}</td>
                                            <td className='table-actions-col'>
                                                {/* For Ashraf Only */}
                                                {(!hasRole(Roles.credit)) && 
                                                    <button type="button" className="btn" onClick={e => showDetails(t, item, navigate)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-card-checklist" viewBox="0 0 16 16">
                                                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                                            <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                                        </svg>
                                                    </button>
                                                }

                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <div className="btn-group" role="group">
                                                    <button
                                                            type="button"
                                                            className={"btn btn-my-dropdown" + ((PayoutStatus.PENDING_FOR_APPROVAL === item.status && payoutMetaData.isPayrollCredit) || (PayoutStatus.PROCESSING === item.status && payoutMetaData.isPayrollFinance) ? ' dropdown-toggle' : " ") + (' ' + item.status)}
                                                            data-bs-toggle={(PayoutStatus.PENDING_FOR_APPROVAL === item.status && payoutMetaData.isPayrollCredit) || (PayoutStatus.PROCESSING === item.status && payoutMetaData.isPayrollFinance) ? "dropdown" : ""}
                                                            aria-expanded="false"
                                                        >
                                                            {t("inputs.payouts.statusList." + item.status).toString()}
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            {
                                                                (payoutMetaData.isPayrollFinance && item.payoutProvider === PaymentProvider.HYPER_PAY && item.status === PayoutStatus.PROCESSING) &&
                                                                financeRoleOperations.map(operationStatus => {
                                                                    return <li key={key.toString() + '1' + item.objectId}><button className="dropdown-item" onClick={e => updateRequest(item.objectId, operationStatus)}>{t(`inputs.payouts.statusList.${operationStatus}`).toString()}</button></li>
                                                                })
                                                            }

                                                            {
                                                                (payoutMetaData.isPayrollCredit && item.status === PayoutStatus.PENDING_FOR_APPROVAL) &&
                                                                creditRoleOperations.map(operationStatus => {
                                                                    return <li key={key.toString() + '1' + item.objectId}><button className="dropdown-item" onClick={e => updateRequest(item.objectId, operationStatus)}>{t(`inputs.payouts.statusList.${operationStatus}`).toString()}</button></li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                            {(!loading && data.length === 0) && <div className='text-center text-muted'>No data available</div>}
                        </div>
                        <div className="col-md-12 ">
                            <div className="table-footer">
                                <div className="results-count">
                                    <p></p>
                                </div>
                                <div className="results-pagination">
                                    <nav>
                                        <ul className="pagination">
                                            <li key={'9991'} className="page-item">
                                                <button className="prev page-link" disabled={page <= 1} onClick={() => changePage(page - 1)}><img alt="" src={Arrow} /></button></li>
                                            {paginationElemnts(data, page, changePage)}
                                            <li key={'9992'} className="page-item"><button className="next page-link" disabled={data.length < 10} onClick={() => changePage(page + 1)}><img alt="" src={Arrow} /></button></li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Sidebar visible={showFilters} position="right" onHide={() => setShowFilters(false)}>
                <div className="filter offcanvas-filter">
                    <div className="mb-2">
                        <h5>{t('inputs.status')}</h5>
                        <div className="d-flex">
                            <input className='form-check' type="checkbox" id='includeRejectedLoansInput'
                                checked={inputs.includeRejectedLoans} onChange={e => setInputs({ ...inputs, includeRejectedLoans: e.target.checked })} />
                            <label htmlFor="includeRejectedLoansInput" className='ms-2'>{t('inputs.includeRejectedLoans') as string}</label>
                        </div>
                    </div>

                    <div className="offcanvas-footer">
                        <div className="results">
                            <strong> {data.length + ' ' + t("results")} </strong>
                        </div>
                        <div className="main-buttons">
                            <button data-bs-dismiss="offcanvas" type="button" className="btn btn-light" onClick={async () => {
                                setInputs({ ...inputs, includeRejectedLoans: false });
                                const filterpayload = {...filters, includeRejectedLoans: inputs.includeRejectedLoans }
                                setFilters(filterpayload);
                                callRequest(setData, filterpayload);
                            }}>{t('clear').toString()}</button>
                            <button type="button" className="btn btn-warning" onClick={() => {
                                const filterpayload = {...filters, includeRejectedLoans: inputs.includeRejectedLoans }
                                setFilters(filterpayload)
                                callRequest(setData, filterpayload);
                            }}>{t('apply').toString()}</button>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}




