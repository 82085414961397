export enum Roles {
    operation = 'OPERATION',
    manager = 'MANAGER',
    anti_fraud = "ANTI_FRAUD",
    finance = "FINANCE",
    credit = "CREDIT",
    help_desk = "HELP_DESK",
    customer_care  = "CUSTOMER_CARE"
}

export enum PayoutStatus {
    READY_FOR_NEW = 'READY_FOR_NEW',
    PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL',
    PROCESSING = 'PENDING_FOR_FINANCE_APPROVAL',
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
    //Below status are applicable only for the ANB payment channel type.
    APPROVED = "APPROVED",
    REJECTED  = "REJECTED",
}

export enum PaymentProvider {
    ANB_PAY = 'ANB_PAY',
    HYPER_PAY = 'HYPER_PAY'
}

export enum RouterPath {
    CUSTOMER_ROUTE = '/customers',
    APP_SETTINGS_ROUTE = '/app_settings',
    LOANS_ROUTE = '/loans',
    PAYOUT_FINANCE_ROUTE = '/payouts-finance',
    STATEMENTS_ROUTE = "/statements",
    PAYOUT_CREDIT_ROUTE = "/payouts-credit",
    REPORTS_ROUTE = '/reports',
    DASH_REPORT = '/dashboard'
}

export enum PayoutPageType {
    CREDIT = 'CREDIT',
    FINANCE = 'FINANCE'
}
export const ALL  = "ALL";

export const PROFILE_KEY = 'profile';
export const ROLES_KEY = 'roles';
export const SESSOIN_EXPIRY_KEY = 'session_expiry';
