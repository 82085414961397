import { useEffect, useMemo, useState } from "react";
import Card from "../../components/Cards";
import {
  fetchLoansByCategoryData, fetchLoansData, fetchLoginData, fetchPaymentsCategoryData,
  fetchRegistrationData,
  LoanStatisticsSummary, LoginCountSummary, PayoutStatisticsSummary, RegistraionStatisticsSummary
} from "./hooks/ScreenHelper";

//generad card data
const generateCardData = (count: number, title: string, incrementPer: number, data: any) => {
  return {
    count,
    title,
    incrementPer,
    data
  }
}

const DashPage = () => {
  const [loginStat, setLoginStat] = useState<LoginCountSummary>();
  const [registrationStats, setRegistrationStats] = useState<RegistraionStatisticsSummary>();
  const [loansStatData, setLoansStatData] = useState<LoanStatisticsSummary>();
  const [paymentsStatData, setPaymentsStatData] = useState<PayoutStatisticsSummary>();
  const [loansCategoryStatData, setLoansCategoryStatData] = useState<LoanStatisticsSummary>();

  console.log(loginStat, "loginState")
  const loginCardData = useMemo(() => {
    const getIncrementPercentage = ()=>{
      let value = loginStat?.logins-loginStat?.prevLogins/loginStat?.prevLogins
      let finalPer = value * 100
      return finalPer
    }
    return generateCardData(loginStat?.logins, "Login Counts", getIncrementPercentage(), loginStat);
  }, [loginStat]);

  const registrationCardData = useMemo(() => {
    return generateCardData(registrationStats?.registrationSummary.registrations, "Registration",
      registrationStats?.registrationSummary.registrations, registrationStats);
  }, [registrationStats]);

  const loansCardData = useMemo(() => {
    return generateCardData(loansStatData?.completed, "Loan Count", loansStatData?.completed, loansStatData);
  }, [loansStatData]);

  const paymentsCardData = useMemo(() => {
    return generateCardData(paymentsStatData?.installments, "Payment Counts", paymentsStatData?.installments, paymentsStatData);
  }, [paymentsStatData]);

  const loansCategoryCardData = useMemo(() => {
    return generateCardData(loansCategoryStatData?.completed, "Loan Category", loansCategoryStatData?.completed, loansCategoryStatData);
  }, [loansCategoryStatData]);


  useEffect(() => {
    fetchLoginData(setLoginStat);
    fetchRegistrationData(setRegistrationStats);
    fetchLoansData(setLoansStatData);
    fetchPaymentsCategoryData(setPaymentsStatData);
    fetchLoansByCategoryData(setLoansCategoryStatData);
  }, []);

  return (
    <div
      className="box_container_dashBoard"
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <div className="row">
        <div className="col">
          <Card cardModel={loginCardData} />
        </div>
        <div className="col">
          <Card cardModel={registrationCardData} />
        </div>
        <div className="col">
          <Card cardModel={loansCardData} />
        </div>
        <div className="col">
        <Card cardModel={paymentsCardData} />
        </div>
        <div className="col">
          <Card cardModel={loansCategoryCardData} />
        </div>
      </div>
    </div>
  );
};

export default DashPage;
