import exportFromJSON from 'export-from-json';
import { NavigateFunction } from 'react-router-dom';
import { setErrorMsg } from '../../../redux/reducers/settingsSlice';
import { ALL, PaymentProvider, PayoutStatus } from '../../../utils/Constants';
import { store } from './../../../redux/store';
import { getPayouts, updatePayoutStatus } from './API';

export interface filters {
    date?: string;
    bank?: string;
    status?: string;
    payoutProvider?: string;
    payoutStatus?: string;
    page?: number;
    pageSize?:number;
    search?: string;
    payoutPageType?: string;
    includeRejectedLoans?: boolean;
}

/**
 * This interface is used to send payout status single request
 */
export interface PayoutStatusRequestData {
    objectId: string;
    payoutStatus: string;
    reasonOfRejection?: string;
}

/**
 * This interface is used to send multiple payout status request
 */
export interface MultiplePayoutStatusRequestData {
    payouts: PayoutStatusRequestData[];
}

export interface Payout {
    objectId: string;
    uniqueId: string;
    transactionId: string;
    nationalId: string;
    keycloakId: string;
    iban: string;
    transferAmount: number;
    requestedAmount: number;
    bankBic: string;
    bankName: string;
    payoutProvider: string;
    transferOption: boolean;
    status: string;
    financialDataId: string;
    sequenceNumber: string;
    customerName: string;
    arabicName: string;
    compAppId: string;
    createdAt: string;
}

export const payoutProviderTypes = [ALL, PaymentProvider.ANB_PAY, PaymentProvider.HYPER_PAY];
export const financePayoutStatusList = [ALL, PayoutStatus.PROCESSING, PayoutStatus.SUCCESSFUL, PayoutStatus.FAILED];
export const creditPayoutStatusList = [ALL, PayoutStatus.PENDING_FOR_APPROVAL, PayoutStatus.APPROVED, PayoutStatus.REJECTED];
export const financeRoleOperations = [PayoutStatus.SUCCESSFUL];
export const creditRoleOperations = [PayoutStatus.APPROVED, PayoutStatus.REJECTED];



//accept popup 
export const acceptPopUp = (data: Payout[], payload: MultiplePayoutStatusRequestData, 
    operation: PayoutStatus, refresshData: () => void, reasonFlag: Boolean) => {
    let reason = "";
    const isRejected = operation === PayoutStatus.REJECTED;

    store.dispatch(setErrorMsg({
        title: "Payout status change to: " + operation,
        icon: null,
        cancelBtnTitle: "Cancel",
        acceptBtnTitle: 'OK',
        modalContentClass: 'small',
        acceptBtnAction: () => {
            if (!isRejected) {
                updatePayoutStatus(payload, (success) => {
                    if (success) refresshData();
                });
            } else {
                if (reason && reason.trim()) {
                    payload.payouts.map(res => res.reasonOfRejection = reason);
                    updatePayoutStatus(payload, (success) => {
                        if (success) refresshData();
                    });
                } else {
                    setTimeout(() => {
                        acceptPopUp(data, payload, operation, refresshData, true);
                    }, 0);
                }
            }
        },
        message: (
            <div className="mb-3" style={{ textAlign: 'start' }}>
                {data.map((res, index) => (
                    <div key={index} className="container text-center">
                        {res?.customerName && <h5>{res?.customerName}</h5>}
                    </div>
                ))}
                {isRejected && <div className="mb-3" style={{ textAlign: 'start' }}>
                    <label htmlFor="recipient-name" className="col-form-label">Reason:</label>
                    <input required type="text" className="form-control" placeholder='Enter reason' onChange={e => { reason = e.target.value; }} />
                    {reasonFlag && <p className='text-danger'>Reason is required</p>}
                </div>}
            </div>
        )
    }))
}

// we calling this function when we need to get all records fromdatabase
export const callRequest = async (setData: React.Dispatch<React.SetStateAction<Payout[]>>, filters: filters, initFilters?: filters) => {
    setData([]);
    await getPayouts(initFilters ?? filters, (_, data) => setData(data));
}

// Open deactivate popup
export const updatePayoutStatusPopUp = (payoutRequest: PayoutStatusRequestData, refresshData: () => void) => {
    let reason = "";
    let blockTitle = 'Payout status change to: ' + payoutRequest.payoutStatus;
    const messageEle = (payoutRequest.payoutStatus === PayoutStatus.FAILED || payoutRequest.payoutStatus === PayoutStatus.REJECTED) ? (<div className="mb-3" style={{ textAlign: 'start' }}>
        <label htmlFor="recipient-name" className="col-form-label">Reason:</label>
        <input type="text" className="form-control" placeholder='Enter reason' onChange={e => { reason = e.target.value }} />
    </div>) : null;

    store.dispatch(setErrorMsg({
        icon: null,
        cancelBtnTitle: "Cancel",
        acceptBtnTitle: "Update",
        modalContentClass: 'small',
        acceptBtnAction: () => {
            payoutRequest.reasonOfRejection = reason;
            const payload = {
                payouts: [payoutRequest]
            }
            updatePayoutStatus(payload, (success) => {
                if (success)
                    refresshData();
            });
        },
        title: blockTitle,
        message: messageEle
    }))
}

// Printing pagination items 
export const paginationElemnts = (dataArray: Payout[], currentPage: number, changePage: (i: number) => void): JSX.Element[] => {
    let pages: JSX.Element[] = [];
    for (let i = currentPage - 1; i < (currentPage + 2); i++) {
        if (i > 0) {
            const element = <li key={i.toString()} className={"page-item" + (i === currentPage ? " active" : "")} ><button className="btn btn-link" onClick={e => changePage(i)}>{i}</button></li>;
            pages.push(element);
        }
        if (i === currentPage && dataArray.length < 10) break;
    }
    return pages;
}

// Open details popup
export const showDetails = (t, payout: Payout, navigate: NavigateFunction) => {
    store.dispatch(setErrorMsg({
        icon: null,
        title: t("More Details"),
        message: (<div className="scroller-area" style={{ maxHeight: 550, padding: 20 }}>
            <div className="row">
                {printElements(t, payout).map(item => item)}
            </div>
        </div>)
    }))
}

const printElements = (t, dataArray: any): JSX.Element[] => {
    const array: JSX.Element[] = [];
    Object.keys(dataArray).forEach((key) => {
        array.push(<div className="col-12 col-md-6" key={key}>
            <div className="bordered-box">
                <strong>{t("inputs.payouts." + key).toString()}</strong>
                <span className='ms-2'>{(dataArray[key] === "" || dataArray[key] === false || dataArray[key] === undefined) ? "-" : dataArray[key].toString()}</span>
            </div>
        </div>)
    });
    return array;
}



export const exportData = async (filters, t) => {
    await getPayouts({...filters, pageSize: Number.MAX_VALUE}, (success, data) => {
        if (success && data && data.length) {
            const fields = {
                createdAt: t("inputs.date"),
                customerName: t("inputs.payouts.customerName"),
                nationalId: t("inputs.payouts.nationalId"),
                bankName: t("inputs.payouts.bankName"),
                iban: t("inputs.payouts.iban"),
                payoutProvider: t("inputs.payouts.payoutProvider"),
                compAppId: t("inputs.payouts.compAppId"),
                financialDataId: t("inputs.payouts.financialDataId"),
                transferAmount: t("inputs.amount"),
                requestedAmount: t("inputs.requestedAmount"),
                status: t("inputs.payouts.status"),
            }
            const newData = [];
            data.map((item: Payout) => {
                return newData.push({
                    createdAt: item.createdAt,
                    customerName: item.customerName,
                    nationalId: item.nationalId,
                    bankName: item.bankName,
                    iban: item.iban,
                    payoutProvider: item.payoutProvider,
                    compAppId: item.compAppId,
                    financialDataId: item.financialDataId,
                    transferAmount: item.transferAmount.toString(),
                    requestedAmount: item.requestedAmount.toString(),
                    status: item.status,
                });
            });
        
            const fileName = 'Payouts-';        
            const exportType = exportFromJSON.types.csv;
            exportFromJSON({ data: newData, fileName, exportType, fields, withBOM: true });
        }
    });
}

