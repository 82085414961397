import React from 'react'
import { paginationElemnts } from '../../pages/CustomersTracker/hook/ScreenHelper'
import Arrow from '../../assets/images/pagination_arrow.svg'

const Pagination = ({data, changepage, page}) => {
  return (
    <div className="col-md-12 ">
    <div className="table-footer">
      <div className="results-count">
        <p></p>
      </div>
      <div className="results-pagination">
        <nav>
          <ul className="pagination">
            <li key={"9991"} className="page-item">
              <button
                className="prev page-link"
                disabled={page <= 1}
                onClick={() => changepage(page - 1)}
              >
                <img alt="" src={Arrow} />
              </button>
            </li>
            {paginationElemnts(data, page, changepage)}
            <li key={"9992"} className="page-item">
              <button
                className="next page-link"
                disabled={data.length < 10}
                onClick={() => changepage(page + 1)}
              >
                <img alt="" src={Arrow} />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  )
}

export default Pagination