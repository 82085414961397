import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "../pages/login/Login";

export const Auth: FC = () => {
  return (
    <Routes>
        <Route path='/' element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
